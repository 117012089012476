<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Gaji Tidak Tetap
              </h5>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="employee_id" rules="required">
                <b-form-group label="NIP" label-for="employee_id" :state="errors.length > 0 ? false : null">
                  <v-select id="employee_id" v-model="dataParent.employee_id"
                    :reduce="(employee_nip) => employee_nip.id" placeholder="Pilih Pegawai"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Tanggal Efektif" label-for="effective_date">
                <validation-provider #default="{ errors }" name="effective_date" rules="required">
                  <b-form-input id="effective_date" v-model="dataParent.effective_date" type="date"
                    :state="errors.length > 0 ? false : null" placeholder="Tanggal Efektif" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>              
              <validation-provider #default="{ errors }" name="component_name" rules="required">
                <b-form-group label="Nama Komponen" label-for="component_name" :state="errors.length > 0 ? false : null">
                  <v-select id="component_name" v-model="dataParent.component_name"
                    :reduce="(employee_nip) => employee_nip.salary_component" placeholder="Pilih Komponen"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listComponent" label="salary_component" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>
            <b-col md="6">
              <b-form-group label="Referensi" label-for="reference">
                <validation-provider #default="{ errors }" name="reference" rules="required">
                  <b-form-input id="reference" v-model="dataParent.reference" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Referensi" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nominal" label-for="nominal">
                <validation-provider #default="{ errors }" name="nominal" rules="required">
                  <b-form-input id="nominal" v-model="dataParent.nominal" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="Nominal" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Deskripsi" label-for="description">
                <validation-provider #default="{ errors }" name="description" rules="required">
                  <b-form-input id="description" v-model="dataParent.description" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Deskripsi" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      listPegawai: [],
      listComponent:[],
      required,
      dataParent: {
        employee_id: '',
        effective_date: '',
        component_name: '',
        reference: '',
        nominal: '',
        description: '',                
      },
      dataIsBPJSTK: [
        { value: 1, text: 'Ada' },
        { value: 0, text: 'Tidak Ada' },
      ],
      dataIsBPJSKes: [
        { value: 1, text: 'Ada' },
        { value: 0, text: 'Tidak Ada' },
      ],
      dataIsBPJSPensiun: [
        { value: 1, text: 'Ada' },
        { value: 0, text: 'Tidak Ada' },
      ],

      dataIsActive: [
        { value: 1, text: 'Aktif' },
        { value: 0, text: 'Tidak Aktif' },
      ],
    }
  },
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  created() {
    this.getpegawai()
    this.getsalarycomponent()
  },
  methods: {
    async getsalarycomponent() {
      try {
        const response = await this.$http.get('/salarycomponents')
        this.listComponent = response.data.data
        console.log(response.data.data);
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
        console.log(response.data.data);
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/nonfixedsalaries', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'gaji-tidak-tetap' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
